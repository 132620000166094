import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from '../../actions/authActions';
import { closeMenu } from '../../actions/appActions';


const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
        dispatch(closeMenu());
    }, [dispatch]);

    return (
        <div>
            <Redirect push to="/login" />
        </div>
    )
}

export default Logout;
