import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

// import { ReactComponent as Venue } from '../../assets/venue.svg';
import { ReactComponent as Camera } from '../../assets/camera.svg';
import { ReactComponent as LoginIcon } from '../../assets/login.svg';
import { ReactComponent as UserCircleIcon } from '../../assets/user-circle.svg';
import { ReactComponent as User } from '../../assets/user.svg';

import './BottomNav.css';

const BottomNav = () => {
    const authSelector = useSelector((state) => state.auth);
    const { isAuthenticated } = authSelector;

    return (
        <div className="bottomNav">
            {isAuthenticated ? (
                <Fragment>
                    <NavLink to="/" exact className="bottomNav__link" activeClassName="active"><Camera style={{ width: '33px', height: '33px' }} />QR Code</NavLink>
                    <NavLink to="/profile" className="bottomNav__link" activeClassName="active" ><User style={{ width: '33px', height: '33px' }} />Profile</NavLink>
                </Fragment>
            ) : (
                <Fragment>
                    <NavLink to="/login" className="bottomNav__link" activeClassName="active"><LoginIcon style={{ width: '33px', height: '33px' }} />Login</NavLink>
                    <NavLink to="/register" className="bottomNav__link" activeClassName="active"><UserCircleIcon style={{ width: '33px', height: '33px' }} />Register</NavLink>
                </Fragment>
            )}
        </div>
    )
}

export default BottomNav;
