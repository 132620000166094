import axios from 'axios';
import Cookie from 'js-cookie';

import { UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL } from '../types/profileTypes';

export const updateProfile = (firstname, lastname, email, repeatEmail, phone, repeatPhone, dob, oldPassword, password, repeatPassword) => async dispatch => {
    try {
        dispatch({ type: UPDATE_PROFILE_REQUEST });

        const { data } = await axios.post('/app_handler.php', { firstname, lastname, email, repeat_email: repeatEmail, phone, repeat_phone: repeatPhone, dob, old_password: oldPassword, password, repeat_password: repeatPassword }, { headers: { 'WG-Method': 'UPDATE_PROFILE', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.successMessage });
    } catch (error) {
        dispatch({ type: UPDATE_PROFILE_FAIL, payload: error?.response.data.errors });
    }
};