import Cookie from 'js-cookie';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL, LOGOUT, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL, RESET_REGISTRATION, CLEAR_SUCCESS_MESSAGE } from '../types/authTypes';

const initialState = {
    user: Cookie.getJSON('user') || null,
    isAuthenticated: Cookie.getJSON('user') ? true : false,
    successMessage: null,
    registrationCompleted: false,
    loading: false,
    errors: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                user: null,
                isAuthenticated: false,
                loading: true,
                errors: null
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
                isAuthenticated: true,
                errors: null
            };
        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                registrationCompleted: true,
                loading: false,
                errors: null
            };
        case REGISTER_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
                successMessage: null,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                errors: null,
                successMessage: action.payload
            };
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
                isAuthenticated: false,
                errors: null
            };
        case RESET_REGISTRATION:
            return {
                ...state,
                registrationCompleted: false,
            };
        case CLEAR_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: null
            };
        default:
            return state;
    }
};