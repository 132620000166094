import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Register from './pages/Register/Register';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ProtectedRoute from './components/routes/ProtectedRoute';

import { getMenu } from './actions/appActions'; 

import './App.css';

export const App = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const { isAuthenticated } = authSelector;

  // Temp solution until server can be access through localhost
  const TestRoute = process.env.NODE_ENV === 'development' ? Route : ProtectedRoute;

  useEffect(() => {
    dispatch(getMenu());
  }, [dispatch]);

  return (
    <Router>
      <div className={`app ${window.location.pathname === '/login' ? 'no-transition' : ''}`}>
        <Switch>
          <TestRoute path="/" exact component={Dashboard} isAuthenticated={isAuthenticated} />
          <TestRoute path="/profile" exact component={Profile} isAuthenticated={isAuthenticated} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/logout" component={Logout} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
}

