import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import Clock from 'react-live-clock';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import './Dashboard.css';

const Dashboard = () => {
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;
    const qr = { 
        secret_code: user?.secret_code,
        customer_id: user?.customer_id
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '87px' }} >
                <h1 className="dashboard__title">QR Code</h1>
                <p className="dashboard__text">Present this qr code upon entry to all venues</p>
                <div className="dashboard__qrcode">
                    {user?.secret_code && user?.customer_id && (
                        <QRCode value={JSON.stringify(qr)} />
                    )}
                </div>
                <div className="dashboard__time"><Clock format={'DD-MM-YYYY HH:mm:ss'} ticking={true} timezone={'Europe/London'} /></div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default Dashboard;
