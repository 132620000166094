import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Logo from '../../assets/4entry-logo.png';
import Menu from '../../images/menu.svg';

import { toggleMenu } from '../../actions/appActions';

import './Header.css';

const Header = (props) => {
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;
    const { isLogin } = props;

    return (
        <div className="header">
            <div className="header__top">
                <img className="header__logo" src={Logo} alt="4Entry" />
                {!isLogin && (
                    <button aria-label="Menu" onClick={() => dispatch(toggleMenu())} className="header__menuIcon"><img src={Menu} alt="Menu" /></button>
                    )}
            </div>
            {user?.displayName && (
                <div className="header__topMessage">{window.location.pathname === '/' ? `Welcome ${user?.displayName}` : props.children}</div>
            )}
        </div>
    )
}

export default Header;
