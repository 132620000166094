import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, component:Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render = {() => isAuthenticated ? (
                <Component />
            ) : (
                <Redirect to="/login" />
            )}
        />
    );
};

export default ProtectedRoute;