import { combineReducers } from 'redux';

import authReducer from './authReducer';
import appReducer from './appReducer';
import profileReducer from './profileReducer';

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    profile: profileReducer
});