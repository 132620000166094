import React from 'react';

const Content = (props) => {
    return (
        <div className="page" style={{ ...props.style }}>
            <div className="page-content">
                {props.children}
            </div>
        </div>
    )
}

export default Content;