import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import Cookie from 'js-cookie';

import Header from '../../components/layout/Header';
import BottomNav from '../../components/layout/BottomNav';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import { updateProfile } from '../../actions/profileActions';
import { closeMenu } from '../../actions/appActions';

import { days, months } from '../../constants/dates';

import 'react-datepicker/dist/react-datepicker.css';
import './Profile.css';

const years = [];
const currentYear = new Date().getFullYear();

for (let i = currentYear; i >= 1920; i--) {
    years.push(i);
}

const Profile = () => {
    const dispatch = useDispatch();
    const profileSelector = useSelector((state) => state.profile);
    const { loading, errors, successMessage } = profileSelector;
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [profileError, setProfileError] = useState(null);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [repeatEmail, setRepeatEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [repeatPhone, setRepeatPhone] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    
    const onSubmit = e => {
        e.preventDefault();

        dispatch(updateProfile(firstName, lastName, email, repeatEmail, phone, repeatPhone, year + '-' + month + '-' + day, oldPassword, password, repeatPassword));
    };

    useEffect(() => {
        dispatch(closeMenu());
        setLoadingProfile(true);
        setProfileError(null);
        async function loadProfile() {
            try {
                const { data } = await axios.get('/app_handler.php', { headers: { 'WG-Method': 'LOAD_PROFILE', 'WG-Key': Cookie.get('accessToken') }});
    
                if (data) {
                    setFirstName(data.firstname);
                    setLastName(data.lastname);
                    setEmail(data.email);
                    setRepeatEmail(data.email);
                    setPhone(data.phone);
                    setRepeatPhone(data.phone);
                    setDay(data.day);
                    setMonth(data.month);
                    setYear(data.year);
                }
              
            } catch (error) {
                setProfileError('Could not load profile. Please check your connection.');
            }
            setLoadingProfile(false);
        };

        loadProfile();
    }, [dispatch]);

    if (profileError) {
        return (
            <Fragment>
                <Header />
                <Menu />
                <Content>
                    <h1>My Profile</h1>
                    <div className="not-found">{profileError}</div>
                </Content>
                <BottomNav />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content>
                <h1>My Profile</h1>
                {successMessage && (<div className="profile__success">{successMessage}</div>)}
                {loadingProfile ? (
                    <ClipLoader />
                ) : (
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label className="form__label" htmlFor="firstName">First Name</label>
                            <input className="form__input" type="text" id="firstName" name="firstName" value={firstName} autoComplete={false} onChange={(e) => setFirstName(e.target.value)} />
                            <div className="error">{errors?.firstname?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="lastName">Last Name</label>
                            <input className="form__input" type="text" id="lastName" name="lastName" value={lastName} autoComplete={false} onChange={(e) => setLastName(e.target.value)} />
                            <div className="error">{errors?.lastname?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="email">Email</label>
                            <input className="form__input" type="text" id="email" name="email" value={email} autoComplete={false} onChange={(e) => setEmail(e.target.value)} />
                            <div className="error">{errors?.email?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="repeatEmail">Retype Email</label>
                            <input className="form__input" type="text" id="repeatEmail" name="repeatEmail" value={repeatEmail} autoComplete={false} onChange={(e) => setRepeatEmail(e.target.value)} />
                            <div className="error">{errors?.repeat_email?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="phone">Phone</label>
                            <input className="form__input" type="text" id="phone" name="phone" value={phone} autoComplete={false} onChange={(e) => setPhone(e.target.value)} />
                            <div className="error">{errors?.phone?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="repeatPhone">Retype Phone</label>
                            <input className="form__input" type="text" id="repeatPhone" name="repeatPhone" value={repeatPhone} autoComplete={false} onChange={(e) => setRepeatPhone(e.target.value)} />
                            <div className="error">{errors?.repeat_phone?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="day">Date of Birth</label>
                            <div className="form__selectRow">
                                <select className="form__select" name="day" value={day} onChange={(e) => setDay(e.target.value)}>
                                    {days.map((day) => (
                                        <option value={day.value}>{day.label}</option>
                                    ))}
                                </select>
                                <select className="form__select" name="month" value={month} onChange={(e) => setMonth(e.target.value)}>
                                    {months.map((month) => (
                                        <option value={month.value}>{month.label}</option>
                                    ))}
                                </select>
                                <select className="form__select" name="year" value={year} onChange={(e) => setYear(e.target.value)}>
                                    {years.map((year) => (
                                        <option value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="error">{errors?.dob?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="old_password">Current Password</label>
                            <input className="form__input" type="password" id="old_password" name="old_password" value={oldPassword} autoComplete={false} onChange={(e) => setOldPassword(e.target.value)} />
                            <div className="error">{errors?.old_password?.message}</div>          
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="password">Password</label>
                            <input className="form__input" type="password" id="password" name="password" value={password} autoComplete={false} onChange={(e) => setPassword(e.target.value)} />
                            <div className="error">{errors?.password?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="repeat_password">Retype Password</label>
                            <input className="form__input" type="password" id="repeat_password" name="repeat_password" value={repeatPassword} autoComplete={false} onChange={(e) => setRepeatPassword(e.target.value)} />
                            <div className="error">{errors?.repeat_password?.message}</div>            
                        </div>
                        <div className="form__buttons">
                            <button aria-label="Update profile" className="form__button form__button--inline" type="submit">Update profile <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                        </div>
                    </form>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default Profile;
